.card {
  margin: 20px 15px;
  padding: 10px 15px;
  border-radius: 10px;
  border-bottom: #2642af 5px solid;
  border-top: #2642af 5px solid;
}
.container {
  display: flex;
  flex-direction: column;
}

.container {
  padding: 15px 10px;
}

.container p {
  font-size: 1rem;
  text-align: justify;
}

.container h2 {
  text-align: center;
}

.container span {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #2642af;
}

.showImage {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
}

.showImage img {
  max-width: 45%;
  padding: 10px 10px;
}

.image {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
}

.image img {
  padding: 10px 10px;
  max-width: 45%;
}

@media screen and (max-width: 900px) {
  .image {
    flex-direction: column;
  }
  .image img {
    padding: 10px 10px;
    max-width: 100%;
  }

  .showImage img {
    max-width: 100%;
  }
  .moreImgs {
    display: none;
  }
}
