/* resets */

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0;
}

ul,
li,
ol {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* resets - end */

.hidden-phone {
  display: none;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  padding: 4px 19px;
  text-decoration: none;
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  color: black;
  cursor: pointer;
}

.btn:hover {
  background-image: linear-gradient(to bottom, #fff, #c5c1c1);
}

.btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  border-radius: 6px;
}

.btn-info {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.btn-info:hover {
  background-image: linear-gradient(to bottom, #5bc0de, #0b7291);
}

.btn-primary {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  background-color: #006dcc;
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
}

.btn-primary:hover {
  background-image: linear-gradient(to bottom, rgb(87, 165, 204), rgb(115, 132, 221));
}

ul,
li,
ol {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

img {
  max-width: 100%;
}

.btn-data {
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 10px;
  cursor: pointer;
  text-decoration: none;
  /* link align center */
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-data:hover {
  background-image: linear-gradient(to bottom, #2f96b4, #5bc0de);
}

@media screen and (min-width: 768px) {
  .hidden-phone {
    display: block;
  }
}
