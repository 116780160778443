

.container {
    display: block;
    padding: 0 20px;
  }
  
  .container h2 {
    color: #084a70;
    font-weight: bold;
  }
  .container p {
    line-height: 20px;
    color: #333;
  }
  
  .features {
    padding: 0 10px;
  }
  
  .features p {
    color: #333;
  }
  
  .features span {
    display: block;
    padding-bottom: 10px;
    color: #084a70;
    font-weight: bold;
    font-size: 21px;
  }
  
  .sidebar blockquote {
    border-left: 5px solid #eee;
    padding-left: 15px;
    color: rgb(63, 61, 61);
  }
  
  .sidebar p {
    margin-bottom: 0;
    color: #302f2f;
  }
  
  .sidebar a {
    color: #084a70;
    font-weight: bold;
    text-decoration: none;
  }
  
  .sidebar small {
    color: #084a70;
    font-weight: bold;
  }
  
  .sidebar small::before {
    content: "\2014 \00A0";
  }
  
  @media screen and (min-width: 768px) {
    .sidebar {
      max-width: 350px;
    }
  
    .container {
      display: flex;
    }
  
    .containerFeatures {
      display: flex;
    }
  }
  