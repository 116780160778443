/* mobile first */

.nav {
  display: flex;
  position: relative;
  background-color: #2642af;
  box-shadow: 0px 2px 10px #000;
}

.menu ul {
  z-index: 99999;
  display: none;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: -40px;
  width: 100%;
  background: linear-gradient(45deg, #020031 0%, #007ed8 100%);
}

.menu.expanded ul {
  display: block;
}

.menu li {
  list-style-type: none;
  text-align: center;
}
.menu li:hover {
  background-color: #007ed8;
}
.menu li a {
  text-decoration: none;
  font-size: bold;
  display: block;
  color: white;
  padding: 1rem 0;
}

.icon {
  display: block;
  border: 1px solid grey;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 70%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}

.icon:hover {
  background-color: #2642af;
}

@media screen and (min-width: 768px) {
  .nav {
    justify-content: space-between;
  }

  .icon {
    display: none;
  }

  .menu ul {
    all: revert;
    display: flex;
    padding: 0 10px;
    margin: 0;
  }

  .menu li {
    padding: 0 5px;
  }

  .menu li a {
    color: white;
  }
}
