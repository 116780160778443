.container {
  padding: 20px 0;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 0 30px rgba(0, 0, 0, 0.075);
  background: linear-gradient(45deg, #020031 0%, #007ed8 100%);
  box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.2), inset 0 -3px 7px rgba(0, 0, 0, 0.2);
}

.container p {
  font-size: 24px;
  line-height: 1.25;
  padding-top: 30px;
  margin-bottom: 0;
  margin-top: 0;
}

.container span {
  font-size: 24px;
  line-height: 1.25;
}

.container a {
  margin: 30px 0;
  padding: 19px 24px;
  font-size: 24px;
  font-weight: 200;
  color: #fff;
  border-radius: 6px;
}
