.container {
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  background: linear-gradient(45deg, #007ed8 0%, #020031 100%);
}

.support {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  flex: 1;
}

.support h2 {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.support a {
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
}

.support button {
  border-radius: 5px;
  padding: 10px 15px;
  background-color: #49afcd;
}

.support button:hover {
  background-color: #749bb4;
}

.footerbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2642af;
}
.footerbar ul {
  display: flex;
  padding: 0;
}

.footerbar li {
  list-style-type: none;
  padding: 0 10px;
}

.footerbar a {
  text-decoration: none;
  text-align: center;
  color: white;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}
