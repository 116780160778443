.card {
  margin: 20px 15px;
  padding: 10px 15px;
  border-radius: 10px;
  border-bottom: #2642af 5px solid;
  border-top: #2642af 5px solid;
}

.container p {
  font-size: 1.2rem;
  text-align: justify;
}
.container h2 {
  text-align: center;
}

.container span {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #2642af;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image img {
  padding: 10px 10px;
  max-width: 45%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn a {
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 10px;
  cursor: pointer;
  text-decoration: none;
}

.btn a:hover {
  background-image: linear-gradient(to bottom, #2f96b4, #5bc0de);
}

@media screen and (max-width: 600px) {
  .image {
    flex-direction: column;
  }
  .image img {
    padding: 10px 10px;
    max-width: 100%;
  }
}
