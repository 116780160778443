.ankiLink {
  padding: 10px 20px;
}
.ankiLink h2 {
  color: black;
  font-weight: bold;
}
.ankiLink p {
  color: red;
}

.ankiLink a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.container {
  display: flex;
}

.container p {
  text-align: center;
  padding: 10px 10px;
  margin-top: 0;
  line-height: 20px;
  color: #333;
}

.container span {
  display: block;
  color: #084a70;
  font-weight: bold;
  font-size: 21px;
}

hr {
  margin: 20px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
}
