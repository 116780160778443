.container {
  padding: 10px 25px;
}

.container h2 {
  color: #317eac;
}

.container hr {
  display: none;
}

.containerList {
  display: flex;
  justify-content: space-between;
}
.removePaddingMargin {
  padding-bottom: 0;
  margin-bottom: 0;
}

.containerList2 {
  min-width: 40%;
}

.navTabs a {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

.navTabs a:hover {
  color: blue;
}

.navTabs li {
  list-style-type: none;
  list-style: none;
  float: left;
  list-style-type: none;
  padding: 10px 10px;
}

.navTabs li.active {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.tabPane {
  display: none;
  padding: 10px 10px;
  padding-top: 40px;
}

.tabPane a {
  text-decoration: none;
}

.tabPane span {
  color: #317eac;
  font-weight: bold;
}

.tabPane h3 {
  display: block;
}

.tabPane code {
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
  background-color: aliceblue;
  font-weight: bold;
}

.active {
  display: block;
}

@media screen and (max-width: 700px) {
  .navTabs li {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px 0;
    width: 90%;
  }

  .container hr {
    display: block;
    width: 100%;
  }

  .tabPane {
    padding-top: 0;
  }
  .containerList2 {
    display: none;
  }
}
